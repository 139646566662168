<template>
  <div>
    <router-view id="content" style="width: calc(100% - 256px);left: 256px; min-width: 750px; top: 90px; position: relative;"></router-view>
  </div>
</template>

<script>

export default {
  components : {
  },
  name: "ExplorePeriod",

  mounted() {
    if (this.$store.getters.getSelectedDataSet !== 'LG모바일카페'){
      alert(`데이터셋을 [LG모바일카페]로 설정해 주세요.`)
      this.$router.push({name : 'ExploreContent'})
    }
  },
  methods : {

  }
};
</script>

<style scoped></style>
